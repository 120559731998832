<template>
  <Loading v-if="initLoading" />
  <van-list
    v-else
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    loading-text="数据加载中..."
    @load="onLoad"
    style="height: 100vh; overflow: auto"
    :immediate-check="false"
  >
    <!-- <div v-else ref="scroll" style="height: 100vh; overflow: auto"> -->
    <div class="box">
      <div class="top">
        <!-- <img src="../../../static/image/left_all.png" @click="back" alt="" /> -->
        <div class="back" @click="back"></div>
        <div class="order_text">我的钱包</div>
      </div>

      <div class="gradual">
        <div>
          <div class="assets">总资产(元)</div>
          <div class="assets_star" v-text="num"></div>
        </div>
        <div class="closeEye_img">
          <img v-show="clos" :src="closeEye" @click="Password" />
          <img v-show="open" :src="openEye" @click="eye" />
        </div>
      </div>

      <div class="gradual" style="margin-top: 20px">
        <div>
          <div class="assets">可用余额</div>
          <div class="assets_star" v-text="num_tow"></div>
        </div>
        <div class="closeEye_img">
          <img v-show="clos_tow" :src="closeEye_tow" @click="Password_tow" />
          <img v-show="open_tow" :src="openEye_tow" @click="eye_toe" />
        </div>
      </div>

      <div class="but_text">
        <div class="but" @click="topUp">充值</div>
        <div class="but" @click="withdraw">提现</div>
      </div>
    </div>

    <!-- <div class="add" @click="bank">
      <div>银行卡</div>
      <div class="add_img">
        <img src="../../../static/image/right.png" alt="" />
      </div>
    </div> -->
    <div class="add" @click="Alipay">
      <div>支付宝</div>
      <div class="add_img">
        <div v-if="anyAlipayaccount.account">
          {{ anyAlipayaccount.account }}
        </div>
        <div v-else>去绑定</div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
    </div>
    <div class="shadow"></div>
    <div class="select">
      <div>账单</div>
      <div class="select_all">
        <span @click="showDate = true">{{ selectDa }}</span>
        <span @click="show = true">{{ all }}</span>
      </div>
    </div>
    <div>
      <div class="details" v-for="item in billArr" :key="item.id">
        <div class="content">
          <!-- <img v-if="item.listPic" alt="" :src="item.listPic" />
          <img v-else src="../../../static/image/wen.png" alt="" /> -->
          <div>
            <div class="img_jin">
              <div class="img_text">{{ item.remark }}</div>
            </div>
            <div class="img_num">{{ item.created_at }}</div>
          </div>
          <div style="text-align: right">
            <div class="img_jin">
              <div class="img_text">{{ item.type }}{{ item.fee }}</div>
            </div>
            <div
              class="img_num"
              :class="
                item.status_name == '成功'
                  ? 'success'
                  : item.status_name == '进行中'
                  ? 'hand'
                  : null
              "
            >
              {{ item.status_name }}
            </div>
          </div>
        </div>
        <!-- <div class="details_num">{{ item.type }}{{ item.fee }}</div> -->
      </div>
    </div>
    <div v-if="!billArr.length" class="def">
      <img src="../../../static/image/hezi2.png" alt="" />
      <p>暂无数据</p>
    </div>
    <!-- <div class="more" @click="handleMore">查看更多</div> -->

    <van-overlay :show="appl" @click="appl = false">
      <div class="wrapper_tow" @click.stop>
        <div class="block_tow">
          <div class="prompt">提示</div>
          <div class="prompt_text">
            <div>您的账户已绑定支付宝，是否更换</div>
            <div>绑定账号？</div>
          </div>
          <div class="prompt_but">
            <div class="prompt_Cancel" @click="cancelDel">取消</div>
            <div class="prompt_sure" @click="sure">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <van-picker
            class="title"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange"
          />
        </div>
      </div>
    </van-overlay>

    <!-- 实名认证  -->
    <van-overlay :show="autonym">
      <div class="wrapper">
        <div class="block1">
          <div>你还未实名认证</div>
          <div>请先实名认证</div>
          <div class="over_box">
            <div class="over_none" @click="autonym = false">取消</div>
            <div class="over_en" @click="$router.push('/Autonym')">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-action-sheet v-model="showDate">
      <van-datetime-picker
        @confirm="selectDate"
        @cancel="showDate = false"
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      />
    </van-action-sheet>
  </van-list>
</template>
<script>
import closeEye from "@/assets/images/close_eye.png"; //闭眼
import openEye from "@/assets/images/open_eye.png"; //睁眼
import moment from "moment";
export default {
  data() {
    return {
      minDate: new Date(2022, 8, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      showDate: false,
      appl: false, // 支付宝弹框
      open: false,
      clos_tow: true,
      open_tow: false,
      autonym: false,
      num: "******",
      num_tow: "******",
      clos: true,
      closeEye: closeEye,
      closeEye_tow: closeEye,
      openEye: openEye,
      openEye_tow: openEye,
      show: false,
      columns: ["全部", "支出", "收入", "提现", "转入"],
      money: "", //余额
      fee: "", //资金
      billArr: [],
      chooseSource: 0,
      all: "全部",
      pageindex: 1,
      pagesize: 20,
      anyAlipayaccount: {}, //我的支付宝
      idcardStatus: "", //是否实名认整
      initLoading: true,
      isHasMore: true,
      finished: false,
      loading: false,
    };
  },
  mounted() {
    this.initLoading = true;
    let params1 = {
      token: localStorage.getItem("token"),
    }; // 我的支付宝
    this.$api.anyAlipayaccount(params1).then((res) => {
      if (res.code == 0) {
        this.anyAlipayaccount = res.data || {};
      }
    });
    this.getBillList(this.chooseSource);

    // 余额,可用余额
    this.$api.withdrawal(params1).then(
      (res) => {
        this.initLoading = false;
        if (res.code == 100000) {
          this.money = res.data.money;
          this.fee = res.data.fee;
        }
      },
      () => {
        this.withdrawal = false;
      }
    );

    //我的个人信息
    let params = {
      token: localStorage.getItem("token"),
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.idcardStatus = res.data.idcardStatus;
      }
    });
  },
  computed: {
    selectDa() {
      return moment(this.currentDate).format("YYYY-MM");
    },
  },
  methods: {
    selectDate() {
      this.showDate = false;
      this.getBillList(this.chooseSource);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onLoad() {
      if (this.isHasMore) {
        this.pageindex++;
        this.getBillList(this.chooseSource);
      } else {
        this.finished = true;
      }
    },
    // 我的信息

    getBillList(source) {
      let params1 = {
        token: localStorage.getItem("token"),
        pageindex: this.pageindex,
        pagesize: this.pagesize,
        source: source,
        date: this.selectDa,
      };
      this.$api.rechargeok(params1).then(
        (res) => {
          this.loading = false;
          if (res.code === 100000) {
            let tmpArry = res ? res.data : [];
            if (this.pageindex === 1) {
              this.billArr = tmpArry;
            } else {
              this.billArr = [...this.billArr, ...tmpArry];
            }
            if (this.pagesize > (res.data || []).length) {
              this.isHasMore = false;
            }
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    handleMore() {
      this.pageindex++;
      this.getBillList(this.chooseSource);
    },

    //支付宝绑定
    Alipay() {
      if (this.anyAlipayaccount.account) {
        this.appl = true;
      } else {
        this.$router.push("/Alipay");
      }
    },

    cancelDel() {
      this.appl = false;
    },

    sure() {
      this.$router.push("/Alipay");
    },

    //个人信息是否绑定支付宝

    //银行卡
    bank() {
      // this.$router.push("/bank");
      this.$toast("暂未开放，敬请期待");
    },
    eye() {
      this.num = "******";
      this.open = false;
      if (this.open == false) {
        this.clos = true;
      }
    },
    Password() {
      this.num = this.money;
      this.open = true;
      if (this.open == true) {
        this.clos = false;
      }
    },

    Password_tow() {
      this.num_tow = this.fee;
      this.open_tow = true;
      if (this.open_tow == true) {
        this.clos_tow = false;
      }
    },

    eye_toe() {
      this.num_tow = "******";
      this.open_tow = false;
      if (this.open_tow == false) {
        this.clos_tow = true;
      }
    },

    // 充值
    topUp() {
      // if (this.idcardStatus == 1) {
      this.$router.push("/topUp");
      // } else {
      //   this.$toast('请先进行实名');
      // }
    },

    onConfirm(value) {
      this.all = value;
      this.pageindex = 1;
      this.isHasMore = true;
      this.finished = false;
      switch (value) {
        case "支出":
          this.chooseSource = 7;
          break;
        case "收入":
          this.chooseSource = 8;
          break;
        case "提现":
          this.chooseSource = 2;
          break;
        case "转入":
          this.chooseSource = 1;
          break;
        case "全部":
          this.chooseSource = 0;
          break;

        default:
          break;
      }
      this.getBillList(this.chooseSource);
      this.show = false;
    },
    onChange(picker, value, index) {
      // Toast(`当前值：${value}, 当前索引：${index}`);
      this.all = `${value}`;
      console.log(value);
    },
    onCancel() {
      // Toast("取消");
      this.show = false;
    },
    ///返回上一页
    back() {
      // this.$router.push('/user');
      this.$router.back();
    },

    //提现
    withdraw() {
      if (this.idcardStatus !== 1) {
        this.autonym = true;
        return;
      }
      // this.$toast.fail("暂未开放");

      // if (this.idcardStatus == 1) {
      this.$router.push("/withdraw");
      // } else {
      //   this.$toast('请先进行实名');
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.details {
  padding: 10px 0;
  margin: 0 27px;
  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
  }
  .details_num {
    font-size: 14px;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img_jin {
      display: flex;
      font-size: 12px;
      align-items: center;
      color: #b1b1b1;
    }
    img {
      width: 44px;
      height: 44px;
      margin-right: 15px;
    }
    .img_text {
      color: black;
      font-size: 13px;
    }
    .img_num {
      font-size: 12px;
      color: #a4a5a9;
      margin-top: 4px;
      &.success {
        color: #0754d3;
      }
      &.hand {
        color: #f5d158;
      }
    }
  }
}
.select {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 27px;
  .select_all {
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
      position: relative;
      padding-right: 15px;
      &::before {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        border-top: 6px solid #999;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
        margin-top: 3px;
        margin-left: 3px;
      }
    }
  }
}
.title {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
/deep/.van-picker-column__item--selected {
  background-color: #f3f4f5;
}

.wrapper_tow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block_tow {
  text-align: center;
  border-radius: 5px;
  width: 80%;
  background-color: #f3f4f5;
  .prompt {
    font-size: 16px;
    line-height: 40px;
    font-weight: 600;
  }
  .prompt_text {
    font-size: 14px;
    line-height: 30px;
  }
  .prompt_but {
    font-size: 18px;
    display: flex;
    color: #0754d3;
    border-top: 1px #a4a5a9 solid;
    .prompt_Cancel {
      width: 49%;
      line-height: 40px;
    }
    .prompt_sure {
      border-left: 1px #a4a5a9 solid;
      line-height: 40px;
      width: 49%;
    }
  }
}
.block {
  width: 100%;
  position: relative;
  top: 180px;
  height: 50%;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.block1 {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  background-color: #fff;
}
.shadow {
  background-color: #f8f8f8;
  height: 5px;
}
.add {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 27px;
  font-size: 15px;
  .add_img {
    display: flex;
    align-items: center;
    img {
      padding-left: 10px;
      width: 7px;
      height: 12px;
    }
  }
}
.but_text {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  padding-left: 47px;
  padding-right: 47px;
}
.but {
  width: 113px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  color: #333333;
  background: rgba(218, 255, 241, 0.4);
  line-height: 36px;
  text-align: center;
}
.gradual {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 45px;
  .closeEye_img {
    width: 17px;
    // height: 9px;
    padding-right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.assets {
  font-size: 14px;
  color: #14796e;
}
.assets_star {
  font-size: 25px;
  color: #333333;
}
.box {
  width: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  min-height: 240px;
  background: linear-gradient(#63c9e8, #a6f9c8);
  background-color: aqua;
  padding-bottom: 20px;
}
.top {
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 21px;
  .back {
    width: 12px;
    height: 12px;
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    transform: rotate(-45deg);
  }
  img {
    width: 11px;
    height: 20px;
  }
  .order_text {
    font-size: 18px;
    color: #000;
    margin-left: 35%;
  }
}
.more {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #b1b1b1;
  padding: 10px 0;
}

.over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  .over_none {
    color: #0754d3;
    width: 74px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #cecece solid;
  }

  .over_en {
    color: #ffffff;
    width: 74px;
    text-align: center;
    background-color: #0754d3;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #0754d3 solid;
  }
}
.def {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  img {
    width: 90px;
    margin-right: -20px;
  }
  p {
    font-size: 14px;
    color: #aaa;
  }
}
</style>
